import * as S from '../../styles';
import NavbarContentItem from '../../NavbarContentItem';

type VideosData = {
  tipo: string;
  link: string;
  prefixo: string;
  svg: string;
  links: unknown;
};
interface PropsActiveTab {
  isActive: boolean;
  newsTabVideos: VideosData;
  setOpen: (boolean) => void;
  setOption: (boolean) => void;
}
export default function VideosTab({ isActive, newsTabVideos, setOpen, setOption }: PropsActiveTab) {
  return (
    <S.ListNavbar className={isActive && 'show'}>
      <S.ListTitle>VÍDEOS</S.ListTitle>
      {Object.entries(newsTabVideos.links).map(link => (
        <NavbarContentItem link={link} setOpen={x => setOpen(x)} setOption={x => setOption(x)} key={link[0]} />
      ))}
    </S.ListNavbar>
  );
}
