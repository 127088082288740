import NavbarContentItem from '../../NavbarContentItem';
import * as S from '../../styles';

type More = {
  svg: string;
  links: unknown;
};
interface PropsActiveTab {
  isActive: boolean;
  newsTabMore: More;
  setOpen: (boolean) => void;
  setOption: (boolean) => void;
}
export default function MoreCanaltech({ isActive, newsTabMore, setOpen, setOption }: PropsActiveTab) {
  return (
    <S.ListNavbar className={isActive && 'show'}>
      <S.ListTitle>MAIS CANALTECH </S.ListTitle>
      {Object.entries(newsTabMore.links).map(link => (
        <NavbarContentItem link={link} setOpen={x => setOpen(x)} setOption={x => setOption(x)} key={link[0]} />
      ))}
    </S.ListNavbar>
  );
}
