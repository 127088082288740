/* eslint-disable no-prototype-builtins */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import Link from 'next/link';
import useAmpComponent from '@/hooks/useAmpComponent';

interface NavbarContentItemProps {
  link: [
    string,
    (
      | string
      | {
          url: string;
          nome?: string;
          icone?: 'video' | 'microphone' | 'forum' | 'google-play' | 'apple';
          target?: '_blank';
          divisor?: 'divider-menu';
        }
    ),
  ];
  setOpen: (boolean) => void;
  setOption: (boolean) => void;
}

export default function NavbarContentItem({ link, setOpen, setOption }: NavbarContentItemProps) {
  const { Icon } = useAmpComponent();
  const targetProps = {};
  if (typeof link[1] === 'object' && link[1].hasOwnProperty('target')) Object.assign(targetProps, { target: '_blank' });
  return (
    <>
      {typeof link[1] === 'object' && link[1].hasOwnProperty('divisor') && <li className="navbar-divider" />}
      {!link[1].hasOwnProperty('classe') ? (
        <li className="list-item-navbar-content" key={link[0]}>
          <Link href={typeof link[1] === 'string' ? link[1] : link[1].url} passHref>
            <a
              title={link[0]}
              {...targetProps}
              onClick={() => {
                setOpen(false);
                setOption(false);
              }}
              rel="noreferrer">
              {typeof link[1] === 'object' && link[1].hasOwnProperty('icone') && <Icon icon={`mdi:${link[1].icone}`} />}
              {link[0]}
            </a>
          </Link>
        </li>
      ) : (
        <li className="cursor-default hover:bg-mglGray50 [&_span]:m-0 [&_span]:flex [&_span]:cursor-[unset] [&_span]:p-4 [&_span]:text-sm [&_span]:font-medium [&_span]:uppercase [&_span]:leading-[14px] [&_span]:text-black/80">
          <span>{link[0]}</span>
        </li>
      )}
    </>
  );
}
