import NavbarContentItem from '../../NavbarContentItem';

import * as S from '../../styles';

type NewsData = {
  tipo: string;
  link: string;
  prefixo: string;
  svg: string;
  links: unknown;
};
interface PropsActiveTab {
  isActive: boolean;
  newsTabData: NewsData;
  setOpen: (boolean) => void;
  setOption: (boolean) => void;
}

export default function NewsTab({ isActive, newsTabData, setOpen, setOption }: PropsActiveTab) {
  return (
    <S.ListNavbar className={isActive && 'show'}>
      <S.ListTitle>Notícias </S.ListTitle>
      {Object.entries(newsTabData.links).map(link => (
        <NavbarContentItem link={link} setOpen={x => setOpen(x)} setOption={x => setOption(x)} key={link[0]} />
      ))}
    </S.ListNavbar>
  );
}
