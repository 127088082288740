import classNames from '@/utils/className';
import { ComponentPropsWithoutRef } from 'react';

const variants = {
  NavBarContainer: {
    menuStatus: {
      true: 'left-0',
      false: 'left-[-60px] tablet:left-0',
    },
  },
  MenuAction: {
    isActive: {
      true: 'bg-titleBlack rounded-half cursor-pointer [&_svg]:fill-mglGray50 [&_svg]:transition-[fill] [&_svg]:duration-[400ms] [&_svg]:ease-in-out',
      false: '[&_svg]:fill-mglGray50/40',
    },
  },
  MenuSuspenso: {
    menuStatus: {
      true: 'left-[60px]',
      false: 'left-[-280px]',
    },
  },
} as const;

export const Cabecalho = ({ children, className, ...rest }: ComponentPropsWithoutRef<'div'>) => {
  return (
    <div
      className={classNames(
        'fixed left-0 right-0 top-0 z-[9998] flex h-[60px] w-full items-center justify-center overflow-auto bg-mglGray900 tablet:w-[60px] tablet:overflow-hidden [&_button]:absolute [&_button]:left-[18px] [&_button]:top-[21px] [&_button]:flex [&_button]:cursor-pointer [&_button]:border-0 [&_button]:bg-transparent tablet:[&_button]:hidden [&_div]:h-[unset] [&_div]:w-[unset] [&_div]:overflow-auto tablet:[&_div]:h-10 tablet:[&_div]:w-10 tablet:[&_div]:overflow-hidden',
        className
      )}
      {...rest}>
      {children}
    </div>
  );
};

export const NavBarContainer = ({ children, className, menuStatus, ...rest }: ComponentPropsWithoutRef<'nav'> & { menuStatus: boolean }) => {
  return (
    <nav
      className={classNames(
        'fixed bottom-0 left-0 top-[60px] z-[1001] flex h-[calc(100%-60px)] w-[60px] flex-col items-center justify-between bg-mglGray900 transition-[left] duration-300 ease-ease [&_ul]:flex [&_ul]:w-10 [&_ul]:flex-col [&_ul]:items-center [&_ul]:justify-center [&_ul]:px-[10px] [&_ul]:pb-[10px] [&_ul]:pt-0 [&_ul]:last:w-[60px]',
        variants.NavBarContainer.menuStatus[menuStatus.toString()],
        className
      )}
      {...rest}>
      {children}
    </nav>
  );
};

export const MenuAction = ({ children, className, isActive, ...rest }: ComponentPropsWithoutRef<'li'> & { isActive: boolean }) => {
  return (
    <li
      className={classNames(
        'relative flex h-10 w-10 items-center justify-center hover:cursor-pointer hover:rounded-half hover:bg-titleBlack [&_span]:absolute [&_span]:right-1 [&_span]:top-2 [&_span]:h-[6px] [&_span]:w-[6px] [&_span]:rounded-half [&_span]:bg-[#0EFDC1] [&_svg]:h-6 [&_svg]:w-6 [&_svg]:transition-[fill] [&_svg]:duration-[400ms] [&_svg]:ease-in-out [&_svg]:hover:fill-mglGray50',
        variants.MenuAction.isActive[isActive.toString()],
        className
      )}
      {...rest}>
      {children}
    </li>
  );
};

export const NavBarMain = ({ children, className, ...rest }: ComponentPropsWithoutRef<'nav'>) => {
  return (
    <nav className={classNames('[@media_only_screen_and_(max-width:426px)]:[&+section]:mt-[60px]', className)} {...rest}>
      {children}
    </nav>
  );
};

export const MenuSuspenso = ({ children, className, menuStatus, ...rest }: ComponentPropsWithoutRef<'nav'> & { menuStatus: boolean }) => {
  return (
    <nav className={classNames('menu-suspenso', variants.MenuSuspenso.menuStatus[menuStatus.toString()], className)} {...rest}>
      {children}
    </nav>
  );
};

export const ListNavbar = ({ children, className, ...rest }: ComponentPropsWithoutRef<'ul'>) => {
  return (
    <ul className={classNames('list-navbar', className)} {...rest}>
      {children}
    </ul>
  );
};

export const ListTitle = ({ children, ...rest }: ComponentPropsWithoutRef<'li'>) => {
  return (
    <li className="m-0 cursor-default px-4 pb-4 pt-0 text-base font-medium uppercase leading-4" {...rest}>
      {children}
    </li>
  );
};
